@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./css/input-styling.css";
@import "./css/button.css";

body {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
  scroll-behavior: smooth;
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100% !important;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus-visible {
  outline: none !important;
}
