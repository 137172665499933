button {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  transition: 0.3s;
}
button:hover {
  /* transform: scale(0.95); */
  opacity: 0.8;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  padding: 10px 24px;
}

button {
  cursor: pointer;
  padding: 10px 24px !important;
  border: 2px solid #212121;
  border-radius: 5px;
  background-color: #fff;
  font-size: 15px !important;
  font-weight: 700 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #000;
}

button.primary {
  background-color: #7cb73f;
  border: 2px solid #7cb73f;
  color: #fff;
}

button.danger {
  background-color: #dd0000;
  border: 2px solid #dd0000;
  color: #fff;
}

button.dark {
  background-color: #212121;
  border: 1px solid #212121;
  color: #fff;
}

button.wide {
  padding: 10px 140px !important;
}

button.small {
  padding: 8px 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

button.lg {
  padding: 15px 24px !important;
  font-size: 18px !important;
}

button.xlg {
  padding: 20px 24px !important;
  font-size: 18px !important;
}
button.xxlg {
  padding: 28px 24px !important;
  font-size: 20px !important;
}

button.full-width {
  width: 100%;
}
